import { QRCodeCanvas } from 'qrcode.react';
import { useEffect, useId, useRef } from 'react';

import DownloadQrButton from './DownloadQrButton';
import externalLinks from '~/constants/externalLinks';

type Props = {
  token: string;
  url: string;
  allowsAr?: boolean;
  name?: string;
};

const MakeQR = (props: Props) => {
  const { token, url, allowsAr, name = '' } = props;
  const arCanvas = useRef<HTMLCanvasElement | null>(null);
  const qrCodeId = useId();
  const styledQrCodeId = useId();

  useEffect(() => {
    if (allowsAr) {
      // Load the banner image on mount and create QR code after it's loaded
      const bannerImage = new Image();
      bannerImage.crossOrigin = 'Anonymous';
      bannerImage.src = externalLinks.QRCodeBanner;

      const createArQrCode = (arBannerImage: HTMLImageElement) => {
        const qrCodeCanvas = document.getElementById(qrCodeId) as HTMLCanvasElement;

        // Get the canvas we'll draw everything to
        const canvas = arCanvas.current;
        const canvasContext = canvas?.getContext('2d');
        if (!canvas || !canvasContext || !qrCodeCanvas) return;

        // This height/width is trying to match our print page height/width
        canvas.width = 300;
        canvas.height = 392;

        // Add AR banner image to top of canvas
        // Cross multiply to dynamically find banner image height
        const scaledBannerImageHeight = (canvas.width * arBannerImage.height) / arBannerImage.width;
        // See https://developer.mozilla.org/en-US/docs/Web/API/CanvasRenderingContext2D/drawImage for the params
        canvasContext.drawImage(
          arBannerImage,
          0,
          0,
          arBannerImage.width,
          arBannerImage.height,
          0,
          0,
          canvas.width,
          scaledBannerImageHeight,
        );

        // Paint background white
        canvasContext.globalCompositeOperation = 'destination-over';
        canvasContext.fillStyle = '#FFF';
        canvasContext.fillRect(0, 0, canvas.width, canvas.height);

        // Give the box a 2 pixel black border
        canvasContext.lineWidth = 2;
        canvasContext.strokeStyle = '#000';
        canvasContext.globalCompositeOperation = 'source-over';
        canvasContext.strokeRect(0, 0, canvas.width, canvas.height);

        // Draw QR code to canvas with margin and offset by banner height
        const qrCodeMargin = 20;
        canvasContext.drawImage(
          qrCodeCanvas,
          0,
          0,
          qrCodeCanvas.width,
          qrCodeCanvas.height,
          qrCodeMargin,
          scaledBannerImageHeight + qrCodeMargin,
          canvas.width - qrCodeMargin * 2,
          canvas.width - qrCodeMargin * 2,
        );

        // Add text to bottom
        canvasContext.font = "35px 'geomanist-regular', Helvetica, sans-serif";
        canvasContext.textAlign = 'center';
        canvasContext.fillStyle = '#000';
        if (name) {
          canvasContext.fillText(name, canvas.width / 2, canvas.height - 14, canvas.width - 10);
        }
      };

      bannerImage.onload = () => {
        createArQrCode(bannerImage);
      };
    }
  }, [qrCodeId, name, allowsAr]);

  if (allowsAr) {
    return (
      <>
        <span className="fk-visuallyHidden">
          {/* The component generates the QR, we hide it here and use it to create the AR canvas */}
          <QRCodeCanvas id={qrCodeId} value={url} size={300} />
        </span>
        <canvas id={styledQrCodeId} ref={arCanvas} />
        <DownloadQrButton token={token} name={name} styledQrCodeId={styledQrCodeId} />
      </>
    );
  }

  return (
    <>
      <QRCodeCanvas id={qrCodeId} value={url} size={200} style={{ border: '8px solid #FFFFFF' }} />
      <DownloadQrButton token={token} name={name} qrCodeId={qrCodeId} />
    </>
  );
};

export default MakeQR;
