import { useEffect } from 'react';

import type { RefObject } from 'react';

// This hook simulates a mouseover event to trigger a tooltip to become activated
// It's currently used for buttons that copy links, when the button is clicked and the link is copied
// the tooltip is activated to display a confirmation that the link was copied
const useTooltipTrigger = (state: boolean, ref: RefObject<HTMLButtonElement>) => {
  useEffect(() => {
    // we want to trigger the tooltip when the user copies the link
    if (state && ref.current) {
      ref.current.dispatchEvent(new MouseEvent('mouseout', { bubbles: true }));
      ref.current.dispatchEvent(new MouseEvent('mouseover', { bubbles: true }));
    }
  }, [state, ref]);
};

export default useTooltipTrigger;
