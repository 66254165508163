const createMarginAroundQrCode = (qrCodeCanvas: HTMLCanvasElement) => {
  // Create a bigger canvas that we'll draw the old canvas to
  const canvas = document.createElement('canvas');
  const canvasContext = canvas.getContext('2d');
  if (!canvasContext) return qrCodeCanvas;

  const additionalMargin = 30;

  // Multiple the margin by 2 to add equal margin on both sides
  canvas.width = qrCodeCanvas.width + additionalMargin * 2;
  canvas.height = qrCodeCanvas.height + additionalMargin * 2;

  // Fill the new canvas with a white background
  canvasContext.fillStyle = '#FFFFFF';
  canvasContext.fillRect(0, 0, canvas.width, canvas.height);

  // Draw originalCanvas to bigger canvas at the x,y of the additionalMargin
  canvasContext.drawImage(qrCodeCanvas, additionalMargin, additionalMargin);

  return canvas;
};

export const downloadQR = (token: string, name?: string, styledQrCodeId?: string, qrCodeId?: string) => {
  const qrFileNameBase = `FlipCode-${token}.png`;

  const qrFileNameCustom = name ? `${name.replace(/\s+/g, '-').replace(/[^a-zA-Z0-9 ]/g, '')}_` : 'QR_';

  let canvas;

  if (styledQrCodeId) {
    canvas = document.getElementById(styledQrCodeId) as HTMLCanvasElement;
  } else if (qrCodeId) {
    canvas = document.getElementById(qrCodeId) as HTMLCanvasElement;
  }
  if (!canvas) return;

  const styledCanvas = createMarginAroundQrCode(canvas);

  const dataURL = styledCanvas.toDataURL('image/png');

  const link = document.createElement('a');
  document.body.appendChild(link);
  link.href = dataURL;
  link.download = qrFileNameCustom + qrFileNameBase;
  link.click();
  link.remove();
};
