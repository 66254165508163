import { Button } from '@flipgrid/flipkit';
import { useMatches } from '@remix-run/react';
import { useTranslation } from 'react-i18next';

import { downloadQR } from '../../helper/qrHelpers';
import HandleIds from '~/constants/handleIds';

import type { RouteTyping } from 'types';

type DownloadQrButtonProps = {
  name?: string;
  token: string;
} & (
  | {
      qrCodeId?: never;
      styledQrCodeId: string;
    }
  | {
      qrCodeId: string;
      styledQrCodeId?: never;
    }
);

const DownloadQrButton = ({ styledQrCodeId, token, name, qrCodeId }: DownloadQrButtonProps) => {
  const { t } = useTranslation();
  const matches = useMatches();
  const ua = (matches.find(m => m.handle?.id === HandleIds.Root)?.data as RouteTyping<'Root'>)?.ua;

  return (
    <>
      {ua && !ua.mobile && <p>{t('qr.rightClickToCopy')}</p>}
      {ua && !(ua.mobile && ua.iOS && ua.chrome) && (
        <Button
          className={ua.mobile ? 'mt1' : ''}
          aria-label={t('qr.downloadQrCode')}
          data-testid="makeQR__button__downloadQR"
          onClick={() => downloadQR(token, name, styledQrCodeId, qrCodeId)}
          size="44"
        >
          {t('common.download')}
        </Button>
      )}
    </>
  );
};

export default DownloadQrButton;
